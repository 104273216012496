import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home - Manufactures of Grinding/Abrasive-Polishing Materials" />
      <Helmet>
        <meta
          name="description"
          content="Seaman Industries are manufacturers of Optical Goods, Barrel-Drum Materials, Grinding-Polishing Materials, as well as media and related supplies"
        />
        <meta name="google-site-verification" content="C4h4EXNOh3XnMMH-eShRDXc7PLQy_1l7N98OzXZYS0Y" />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1 className="sr-only">Seaman Industries are specializes in grinding and polishing materials used in barrel and drum for spectacle frame parts.</h1>
          <span className="i-title display-2">When your frame needs surface finishing, start with </span>
          <span className="i-title display-2 p-color">Seaman </span>
          <span className="i-title display-2 s-color">Industries</span>
          {/* <p>
            We are Manufactures of Optical Goods, Barrel-Drum Materials, Grinding & Polishing Materials
          </p> */}
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-5 pt-xl-10 si-intro">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">About Us</h2>
          </div>
          {markdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12">
              <div className="card about-teaser">
                <div className="card-content">
                  <h2>
                    We are Manufacturers of Optical Goods, Barrel-Drum Materials, Grinding & Polishing Materials
                    {/* <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link> */}
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/about">
              Know more About Us
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Our Features</h2>
          </div>
          {json.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.image)} alt={edge.node.title} />
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt(
            format: PLAIN
            pruneLength: 1000
            truncate: true
          )
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;
